<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">客户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">客户列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ title }}</span>
      </div>
    </div>
    <el-card style="padding:20px">
      <el-form
        label-position="right"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="60px"
        style="text-align:left"
      >
        <el-col :span="12">
          <el-form-item label="企业名称" label-width="110px" prop="name">
            <el-input v-model="ruleForm.name" style="width: 100%" placeholder="请输入企业名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="统一信用编码" label-width="110px" prop="idCardNumber">
            <el-input v-model="ruleForm.idCardNumber" maxlength="18" placeholder="请输入企业统一信用编码"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="联系人" label-width="110px" prop="username">
            <el-input v-model="ruleForm.username" style="width: 100%" placeholder="请输入联系人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式" label-width="110px" prop="userPhone">
            <el-input v-model="ruleForm.userPhone" type="number" placeholder="请输入联系方式"></el-input>
          </el-form-item>
        </el-col>
        <el-button
          type="primary"
          v-if="!isShow"
          style="float: right;"
          @click="onSubmit('ruleForm')"
        >保存</el-button>
        <el-button type="primary" v-else style="float: right;" @click="goSubmit('ruleForm')">保存</el-button>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
import { phoneReg } from '../../../src/lib/global.js';
export const customerAdd = api()('customer.create.json');
export const customerInfo = api()('customer.info.json');
export const customerEdit = api()('customer.eidit.json');
export default {
  data () {
    return {
      ruleForm: {
        name: '',
        idCardNumber: '',
        username: '',
        userPhone: '',
        code: ''
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        idCardNumber: [{ required: true, message: '请输入企业信用代码', trigger: 'blur' },
        { pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_OZSVa-z\W]{10}$/g, message: '请输入正确企业统一信用代码' }],
        username: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        userPhone: [{ required: true, message: '请输入联系人电话', trigger: 'blur' },
        { pattern: phoneReg, message: '请输入正确的手机号' }],
      },
      code: ''
    }
  },
  async created () {
    this.code = this.$route.query.code;
    if (this.code) {
      this.title = '编辑客户'
      await this.logoData()
      this.isShow = true
    }
    else {
      this.title = '添加客户'
      this.isShow = false
    }
  },
  methods: {
    async logoData () {
      const params = {
        code: this.code
      };
      const res = await customerInfo(params);
      this.userInfo = res
      Object.keys(this.ruleForm).forEach(it => {
        this.ruleForm[it] = res[it]

      })
    },
    onSubmit (formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          customerAdd(this.ruleForm).then(() => {
            this.close(formName);
            window.history.back(-1);
          });
        } else {
          return false;
        }
      });
    },
    goSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          delete this.ruleForm.phone
          delete this.ruleForm.code
          console.log(this.ruleForm, '321')
          customerEdit({
            ...this.ruleForm,
            code: this.code
          }).then(() => {
            // this.close(formName);
            // window.history.back(-1);
            this.$message({
              message: '保存成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
  },
}
</script>